import { HeroTitle } from "@/app/components/hero-title";
import { SearchForm } from "@/app/components/search-form";
import { Wrapper } from "@/app/components/wrapper";
import { defaultMetaTags } from "@/app/lib/meta-description.server";
import { generateRandomRecord } from "@/app/lib/search-utils.server";
import { getSearchDropdownContent } from "@/app/lib/utils.server";
import { Adsense } from "@ctrl/react-adsense";
import {
  type LoaderFunctionArgs,
  type MetaFunction,
  json,
} from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { HeroSpecialties } from "../components/hero-specialties";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (data?.seo !== undefined) {
    return data.seo;
  }
  return [];
};
// biome-ignore lint/correctness/noEmptyPattern: <remix stuff>
export const loader = async ({}: LoaderFunctionArgs) => {
  const specialtiesSeo = generateRandomRecord(12, ["specialties"]);
  const citiesSeo = generateRandomRecord(12, ["cities"]);
  const healthInsuracesSeo = generateRandomRecord(12, ["healthInsurance"]);
  const mixSeo = generateRandomRecord(12, [
    "specialties",
    "cities",
    "healthInsurance",
  ]);

  const { healthInsurancesList, specialtiesList, statesAndCities } =
    await getSearchDropdownContent();

  const seo = defaultMetaTags();

  return json({
    specialtiesSeo,
    citiesSeo,
    healthInsuracesSeo,
    mixSeo,
    healthInsurancesList,
    specialtiesList,
    statesAndCities,
    seo,
  });
};

export default function Home(): JSX.Element {
  const data = useLoaderData<typeof loader>();

  return (
    <>
      <HeroTitle />
      <SearchForm
        healthInsurances={data.healthInsurancesList}
        specialties={data.specialtiesList}
        stateAndCities={data.statesAndCities}
        query={undefined}
      />
      <Wrapper>
        <Adsense
          className="min-h-[250px] md:min-h-[250px] lg:min-h-[90px] my-5 w-full"
          client="ca-pub-9847031185780341"
          slot="9473274455"
          style={{ display: "block" }}
          responsive="true"
          format="auto"
        />
      </Wrapper>
      <div className="ml-auto mr-auto max-w-4xl pl-5 pr-5">
        <HeroSpecialties
          cities={data.citiesSeo}
          healthInsuraces={data.healthInsuracesSeo}
          mix={data.mixSeo}
          specialties={data.specialtiesSeo}
        />
      </div>
      <Wrapper>
        <Adsense
          className="min-h-[250px] md:min-h-[250px] lg:min-h-[90px] my-5 w-full"
          client="ca-pub-9847031185780341"
          slot="6211787848"
          style={{ display: "block" }}
          responsive="true"
          format="autorelaxed"
        />
      </Wrapper>
    </>
  );
}
